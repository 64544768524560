/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function( $ ) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.

	function royalwaste_initWindows() {
		var winXS = winSM = winMD = winLG = false;
		// window size
		if ( $( window ).width() <= 767 ) {
			winXS = true;
		} else if ( $( window ).width() >= 768 && $( window ).width() <= 991 ) {
			winSM = true;
		} else if ( $( window ).width() >= 992 && $( window ).width() <= 1199 ) {
			winMD = true;
		} else if ( $( window ).width() >= 1200 ) {
			winLG = true;
		}
	}

	function royalwaste_preloader() {
		/*$('body').imagesLoaded(function () {
		  $('body').addClass('loaded');
		});*/
		setTimeout( function() {
			$( 'body' ).addClass( 'loaded' );
		}, 300 );
	}

	var contactInfo = document.querySelector('footer .contact-info');
	var FooterWidget = document.querySelector('footer .widget-section');

	function royalwaste_align_footer_links() {

		var contactInfooffsets = contactInfo.getBoundingClientRect();
		var FooterWidgetoffsets = FooterWidget.getBoundingClientRect();

		console.log(contactInfooffsets);
		console.log(FooterWidgetoffsets);

		var contactInfoleft = contactInfooffsets.left;
		var FooterWidgetLeft = FooterWidgetoffsets.left;

		if ( $( window ).width() >= 767 ) {
			$('footer .widget-section').css('margin-left', ( contactInfoleft - FooterWidgetLeft ));
		} else {
			$('footer .widget-section').removeAttr('style');
		}

	}

	function royalwaste_reset_announcement() {

		var isInViewport = function (elem) {
			var bounding = elem.getBoundingClientRect();
			return (
				bounding.top >= 0 &&
				bounding.left >= 0 &&
				bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
				bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
			);
		};

		var bwl_news_ticker = document.querySelector('#news_feed_ticker');
		window.addEventListener('scroll', function (event) {
			if (isInViewport(bwl_news_ticker)) {
				bwl_news_ticker.classList.add('fade-in-element');
				bwl_news_ticker.classList.remove('hidden');
			} else {
				bwl_news_ticker.classList.remove('fade-in-element');
				bwl_news_ticker.classList.add('hidden');
			}
		}, false);
	}

	function royalwaste_init() {
		var back_to_button_btn = $('.back-to-button a');
		$('[data-toggle="tooltip"]').tooltip();
		$(window).scroll(function() {
			if ($(window).scrollTop() > 300) {
				back_to_button_btn.addClass('show');
			} else {
				back_to_button_btn.removeClass('show');
			}
		});

		back_to_button_btn.on('click', function(e) {
			e.preventDefault();
			$('html, body').animate({scrollTop:0}, '300');
		});


		$('.tag-cloud-link').removeAttr('style');
		$("a[href*='#']").mPageScroll2id({
			offset:70
		});
		wow = new WOW( { mobile: false } );
		wow.init();
		royalwaste_reset_announcement();

		jQuery( "[name=\"Phone\"], [name=\"PhoneNumber\"], [name=\"FaxNumber\"]" ).mask( "(999) 999-9999" );

		$('.floating-button').click(function(){
			var floatingForm = $('.floating-form');
			if (floatingForm.hasClass('visible')){
				floatingForm.animate({"right":"-1000px"}, "slow").removeClass('visible');
			} else {
				floatingForm.animate({"right":"0px"}, "slow").addClass('visible');
			}
		});

		$('.floating-form .close-icon').click(function(){
			var floatingForm = $('.floating-form');
			floatingForm.animate({"right":"-1000px"}, "slow").removeClass('visible');
		});


		$('.header-search-icon').click(function(){
			var websiteSearchForm = $('.website-search-form');
			if (websiteSearchForm.hasClass('visible')){
				websiteSearchForm.slideUp();
				websiteSearchForm.removeClass('visible');
			} else {
				websiteSearchForm.slideDown();
				websiteSearchForm.addClass('visible');
			}
		});

		$('.website-search-form .close-icon').click(function(){
			var websiteSearchForm = $('.website-search-form');
			websiteSearchForm.slideUp();
			websiteSearchForm.removeClass('visible');
		});

		$('.placeholder').click(function() {
			$(this).parents('.form-group').find('input').focus();
		});

		$('.form-control').focus(function() {
			$(this).parents('.form-group').find('.placeholder').addClass('move-placeholder-at-top');
		});

		$('.form-control').blur(function() {
			var $this = $(this);
			if ($this.val().length == 0)
				$(this).parents('.form-group').find('.placeholder').removeClass('move-placeholder-at-top');
		});

		$('.form-control').blur();

		$('.WhenCanYouStart input').datepicker({
			onSelect: function (dateText, inst) {
				$('.WhenCanYouStart').parents('.form-group').find('.placeholder').addClass('move-placeholder-at-top');
			}
		});

		document.addEventListener( 'wpcf7mailsent', function( event ) {
			$('.wpcf7-form').find('.placeholder').removeClass('move-placeholder-at-top');
		}, false );

		$('.DesiredPosition select option:first, .SalaryRequirements select option:first, .ServiceType select option:first, .PropertyType select option:first').val('N/A');

		var floatingFormDescription = sage_params.floating_form_description;
		$('.request-service-floating .description span').html(floatingFormDescription);

		royalwaste_align_footer_links();

	}

	var Sage = {
		// All pages
		'common': {
			init: function() {
				royalwaste_initWindows();
				royalwaste_preloader();
				royalwaste_init();
			},
			finalize: function() {

			}
		},
		// Home page
		'home': {
			init: function() {
				$('.progress-bar-wrapper').on('appear', function(event, $all_appeared_elements) {
					$( ".progress-bar-wrapper .item" ).each(function( index ) {
						$progressCircle = $(this).find('.ko-progress-circle');
						var currentPercentage = $progressCircle.data('percentage');
						$progressCircle.attr('data-progress', currentPercentage);
					});
				});
			},
			finalize: function() {

			}
		},
		'organic_waste_recycling_composting': {
			init: function() {
				$('#menu-item-12').removeClass('current-menu-ancestor');
			},
		},
		// Team Page
		'join_the_royal_team': {
			init: function() {
				$( '.uploadresume' ).focusin( function() {
					$this = $( this );
					setTimeout(function(){
						var filename = $this.val();
						if ( filename === '' ) {
							$( ".UploadResumeFile input" ).click();
						}
					}, 400);

				} );

				$( ".uploadresumefile" ).change( function(e) {
					var filename = $( this ).val().split( '\\' ).pop();
					$( '.uploadresume' ).val( filename );
				} );
			},
			finalize: function() {
				if ( $( window ).width() <= 767 ) {
					$('[for="mostrecentemployment"]').text('Your most recent place of employment?');
				} 
			}
		},

		'why_royal': {
			init: function() {
				$('.bxslider').bxSlider({
					auto: false,
					mode: 'fade',
					autoControls: true,
					adaptiveHeight: true,
					pager: false,
					useCSS: false,
					nextText: '<i class="fa fa-angle-right" aria-hidden="true"></i>',
					prevText: '<i class="fa fa-angle-left" aria-hidden="true"></i>'
				});
			},
		},
		'areas_we_serve': {
			init: function() {
				$('.progress-bar-wrapper').on('appear', function(event, $all_appeared_elements) {
					$( ".progress-bar-wrapper .item" ).each(function( index ) {
						$progressCircle = $(this).find('.ko-progress-circle');
						var currentPercentage = $progressCircle.data('percentage');
						$progressCircle.attr('data-progress', currentPercentage);
					});
				});
			},
		},
		'single_stream_recycling': {
			init: function() {
				$('.progress-bar-wrapper').on('appear', function(event, $all_appeared_elements) {
					$( ".progress-bar-wrapper .item" ).each(function( index ) {
						$progressCircle = $(this).find('.ko-progress-circle');
						var currentPercentage = $progressCircle.data('percentage');
						$progressCircle.attr('data-progress', currentPercentage);
					});
				});
			},
		},
		'sustainability': {
			init: function() {
				$('.progress-bar-wrapper').on('appear', function(event, $all_appeared_elements) {
					$( ".progress-bar-wrapper .item" ).each(function( index ) {
						$progressCircle = $(this).find('.ko-progress-circle');
						var currentPercentage = $progressCircle.data('percentage');
						$progressCircle.attr('data-progress', currentPercentage);
					});
				});
			},
		},
		'container_rentals': {
			init: function() {
				var $j = jQuery.noConflict();

				var realSlider= $j("ul#bxslider").bxSlider({
					speed:1000,
					mode: 'fade',
					pager:false,
					adaptiveHeight: true,
					useCSS: false,
					nextText: '<i class="fa fa-angle-right" aria-hidden="true"></i>',
					prevText: '<i class="fa fa-angle-left" aria-hidden="true"></i>',
					infiniteLoop:false,
					hideControlOnEnd:true,
					onSlideBefore:function($slideElement, oldIndex, newIndex){
						changeRealThumb(realThumbSlider,newIndex);

					}

				});

				var realThumbSlider=$j("ul#bxslider-pager").bxSlider({
					minSlides: 2,
					maxSlides: 5,
					slideWidth: 156,
					slideMargin: 25,
					moveSlides: 1,
					pager:false,
					speed:1000,
					infiniteLoop:false,
					hideControlOnEnd:true,
					useCSS: false,
					nextText: '<i class="fa fa-angle-right" aria-hidden="true"></i>',
					prevText: '<i class="fa fa-angle-left" aria-hidden="true"></i>',
					onSlideBefore:function($slideElement, oldIndex, newIndex){
						/*$j("#sliderThumbReal ul .active").removeClass("active");
						$slideElement.addClass("active"); */

					}
				});

				linkRealSliders(realSlider,realThumbSlider);

				if($j("#bxslider-pager li").length<5){
					//$j("#bxslider-pager .bx-next").hide();
				}

				// sincronizza sliders realizzazioni
				function linkRealSliders(bigS,thumbS){

					$j("ul#bxslider-pager").on("click","a",function(event){
						event.preventDefault();
						var newIndex=$j(this).parent().attr("data-slideIndex");
						bigS.goToSlide(newIndex);
					});
				}

				//slider!=$thumbSlider. slider is the realslider
				function changeRealThumb(slider,newIndex){

					var $thumbS=$j("#bxslider-pager");
					$thumbS.find('.active').removeClass("active");
					$thumbS.find('li[data-slideIndex="'+newIndex+'"]').addClass("active");

					if(slider.getSlideCount()-newIndex>=4)slider.goToSlide(newIndex);
					else slider.goToSlide(slider.getSlideCount()-4);

				}
			},
		},

	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function( func, funcname, args ) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[ func ];
			fire = fire && typeof namespace[ func ][ funcname ] === 'function';

			if ( fire ) {
				namespace[ func ][ funcname ]( args );
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire( 'common' );

			// Fire page-specific init JS, and then finalize JS
			$.each( document.body.className.replace( /-/g, '_' ).split( /\s+/ ), function( i, classnm ) {
				UTIL.fire( classnm );
				UTIL.fire( classnm, 'finalize' );
			} );

			// Fire common finalize JS
			UTIL.fire( 'common', 'finalize' );
		}
	};

	// Load Events
	$( document ).ready( UTIL.loadEvents );

	var doit;
	$(window).resize(function(){
		clearTimeout(doit);
		doit = setTimeout(function() {
			royalwaste_align_footer_links();
		}, 1500);

	});

})( jQuery ); // Fully reference jQuery after this point.
